import { Routes, Route } from "react-router-dom";
import { QRGeneric } from "./QRCodes/Generic";
import { QRWiFi } from "./QRCodes/WiFi";
import { QRPrint } from "./QRCodes/Marker";

function App() {
  return (
    <Routes>
      <Route path="/wifi" element={<QRWiFi />} />
      <Route path="/connect" element={<QRGeneric />} />
      <Route path="/marker" element={<QRPrint />} />
      <Route path="*" element={<QRGeneric />} />
    </Routes>
  );
}

export default App;
