import { QRCodeSVG } from "qrcode.react";

export function QR({ content }: { content: string }) {
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      }}
    >
      <QRCodeSVG
        value={content}
        size={2048}
        style={{
          display: "flex",
          width: "calc(90vw)",
          height: "calc(90vh)",
        }}
      />
    </div>
  );
}
