import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import qs from "query-string";
import { QR } from "./QR";

function CreateWifiString(ssid: string, password: string | null): string {
  if (password === "") password = null;

  for (let char in ["?", "\\", "$", "[", "]"]) {
    if (ssid.includes(char)) {
      alert("Illegal characters in SSID");
      break;
    }
  }

  if (password) {
    password = password!.replaceAll(/([\\;,:])/gm, `\\$&`);
  }

  const str = password
    ? `WIFI:T:WPA;S:${ssid};P:${password};;`
    : `WIFI:T:nopass;S:${ssid};;;`;
  console.info(str);
  return str;
}

export function QRWiFi() {
  useEffect(() => {
    document.title = "Wifi Code";
  }, []);

  const navigate = useNavigate();
  const { search } = useLocation();

  const [qr, setQR] = useState<string>("");

  useEffect(() => {
    const query = qs.parse(search);
    let foundSSID = query["ssid"];
    let foundPassword = query["pass"];

    if (typeof foundSSID === "string") {
      setQR(CreateWifiString(foundSSID, foundPassword as string));
    } else {
      // Manual entry
      const inputSSID = window.prompt("Enter Name (SSID)");
      if (inputSSID) {
        let inputPass = window.prompt("Enter Password (WPA2)");
        if (inputPass === "") {
          inputPass = null;
        }

        const args = {
          ssid: inputSSID,
          pass: inputPass,
        };
        navigate({
          search: `${qs.stringify(args, { skipNull: true })}`,
        });
      } else {
        alert("Missing query string. Try adding ?ssid=<name>&pass=<pw>");
      }
    }
  }, [search, navigate]);

  return !qr ? null : <QR content={qr} />;
}
