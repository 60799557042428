import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import qs from "query-string";
import { QR } from "./QR";

export function QRGeneric() {
  useEffect(() => {
    document.title = "QR Code";
  }, []);

  const navigate = useNavigate();
  const { search } = useLocation();

  const [qr, setQr] = useState<string>("https://fologram.com");

  useEffect(() => {
    let found = qs.parse(search)["qr"];

    if (typeof found === "string") {
      setQr(found);
      console.info(found);
    } else {
      // Manual entry
      const prompted = window.prompt(
        "Enter QR code content",
        "https://fologram.com"
      );
      if (prompted) {
        navigate({
          search: `?${qs.stringify({ qr: prompted })}`,
        });
      } else {
        alert("Missing query string. Try adding ?qr=<your-content> ");
      }
    }
  }, [search, navigate]);

  return !qr ? null : <QR content={qr} />;
}
