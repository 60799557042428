import {
  Document,
  Page,
  Text,
  View,
  Polygon,
  PDFViewer,
  Svg,
  Rect,
  Image,
  Line,
  BlobProvider,
} from "@react-pdf/renderer";
import { DocumentProps, PageSize } from "@react-pdf/types";
import queryString from "query-string";
import { useCallback, useEffect, useState } from "react";
/*@ts-ignore no types for lib https://github.com/defunctzombie/qr.js/tree/master/lib */
import QRJS from "qr.js";
import { useLocation, useNavigate } from "react-router-dom";
const CORNER_COLOR = "#673ab7";

const isMobile =
  /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

type ValidPaperSize = "a3" | "a4" | "legal" | "letter";

type QueryString = {
  qr?: string[];
  paper?: ValidPaperSize;
};

type Paper = {
  size: PageSize;
  width: number;
  height: number;
  name: string;
};

export const paperSize: Record<string, Paper> = {
  a4: { size: "A4", width: 210, height: 297, name: "A4" },
  a3: { size: "A3", width: 297, height: 420, name: "A3" },
  letter: { size: "LETTER", width: 215.9, height: 278.9, name: "Letter" },
  legal: { size: "LEGAL", width: 215.9, height: 355.9, name: "Legal" },
};

const randomString = () =>
  Date.now().toString(16).split("").reverse().join("").slice(0, 4);

export function QRPrint() {
  useEffect(() => {
    document.title = "QR Print";
  }, []);

  const { search } = useLocation();
  const navigate = useNavigate();

  const [mutableContents, setMutableContents] = useState<string[]>([
    randomString(),
  ]);
  const [paper, setPaper] = useState<ValidPaperSize>("a4");

  useEffect(() => {
    const qs = queryString.parse(search, {
      arrayFormat: "comma",
    }) as QueryString;
    const contents =
      qs.qr == null ? [randomString()] : Array.isArray(qs.qr) ? qs.qr : [qs.qr];
    setMutableContents(contents);
    if (qs.paper) {
      setPaper(qs.paper);
    }
    setPdfDoc(
      <QRPdfDoc
        qrcodes={contents}
        paper={(qs.paper as ValidPaperSize) ?? "a4"}
      />
    );
  }, [search]);

  const [pdfDoc, setPdfDoc] = useState<React.ReactElement<DocumentProps>>();
  const [needsUpdate, setNeedsUpdate] = useState<boolean>(false);

  const refresh = useCallback(
    (newContents: string[], newPaper: ValidPaperSize) => {
      // Update the query string
      const query: QueryString = {
        qr: newContents,
        paper: newPaper,
      };
      navigate({
        search: queryString.stringify(query, { arrayFormat: "comma" }),
      });
      setNeedsUpdate(false);
    },
    [navigate]
  );

  return (
    <>
      <div
        id="ui"
        style={{
          flexDirection: "column",
          width: "min(100vw - 48px, 400px)",
          overflowY: "auto",
          position: "absolute",
          backgroundColor: "white",
          borderRadius: 4,
          bottom: 24,
          left: 24,
          boxShadow:
            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
          border: "rgba(0,0,0,0.2)",
          borderWidth: 1,
          borderStyle: "solid",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              margin: 8,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <label
              htmlFor="paper"
              style={{
                fontSize: 12,
              }}
            >
              Paper
            </label>
            <select
              name="paper"
              id="paper"
              value={paper}
              onChange={(change) => {
                setPaper(change.target.value as ValidPaperSize);
                refresh(mutableContents, change.target.value as ValidPaperSize);
              }}
              style={{
                marginLeft: 8,
                display: "flex",
                width: "100%",
                padding: 4,
              }}
            >
              {Object.keys(paperSize).map((key) => (
                <option key={key} value={key}>
                  {paperSize[key].name}
                </option>
              ))}
            </select>
          </div>

          <div
            style={{
              maxHeight: `min(40vh, 400px)`,
              overflowY: "auto",
              marginBottom: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: 12,
                display: "flex",
                padding: 8,
                paddingBottom: 4,
              }}
            >
              Contents
            </label>
            {mutableContents.map((item, ind) => (
              <div
                key={ind}
                style={{
                  display: "flex",
                  padding: 8,
                  paddingTop: 0,
                  paddingBottom: 4,
                }}
              >
                <input
                  type="text"
                  value={item}
                  style={{
                    flexGrow: 1,
                  }}
                  onChange={(ev) => {
                    setMutableContents((p) => {
                      const arr = [...p];
                      arr[ind] = ev.target.value;
                      return arr;
                    });
                    setNeedsUpdate(true);
                  }}
                />
                <button
                  style={{
                    width: 22,
                    marginLeft: 4,
                  }}
                  disabled={mutableContents.length <= 1}
                  onClick={(ev) => {
                    setMutableContents((p) => {
                      const arr = [...p];
                      arr.splice(ind, 1);
                      return arr;
                    });
                    setNeedsUpdate(true);
                  }}
                >
                  -
                </button>
                <button
                  style={{
                    width: 22,
                    marginLeft: 4,
                  }}
                  onClick={() => {
                    setMutableContents((p) => {
                      const arr = [...p];
                      arr.splice(ind + 1, 0, randomString());
                      return arr;
                    });
                    setNeedsUpdate(true);
                  }}
                >
                  +
                </button>
              </div>
            ))}
          </div>

          <button
            style={{ margin: 4, marginRight: 8 }}
            disabled={!needsUpdate}
            onClick={() => {
              refresh(mutableContents, paper);
            }}
          >
            {needsUpdate ? "Update" : "Up to date"}
          </button>

          {isMobile && pdfDoc && (
            <BlobProvider document={pdfDoc}>
              {({ blob, url, loading, error }) => (
                <button
                  onClick={(e) => {
                    if (url) window.location.href = url;
                  }}
                  style={{
                    margin: 8,
                  }}
                  disabled={loading}
                >
                  Download
                </button>
              )}
            </BlobProvider>
          )}
        </div>
      </div>
      <PDFViewer style={{ width: "100vw", height: "100vh", border: "none" }}>
        {pdfDoc}
      </PDFViewer>
    </>
  );
}

export function QRPdfDoc({
  qrcodes,
  paper,
}: {
  qrcodes: string[];
  paper: string;
}) {
  // Work out module size so we know how much to excavate (15%)
  const margin = 15;

  const paperWidth = paperSize[paper].width;
  const paperHeight = paperSize[paper].height;

  let orientation: "portrait" | "landscape" = "landscape";

  return (
    <Document>
      {qrcodes.map((qr, ind) => (
        <Page size={paperSize[paper].size} key={ind} orientation={orientation}>
          <View
            id="cut line"
            style={{
              position: "absolute",
              borderRight:
                orientation === "landscape" ? `0.3mm solid silver` : 0,
              borderBottom:
                orientation === "portrait" ? `0.3mm solid silver` : 0,
              height: `${paperWidth}mm`,
              width: `${paperWidth}mm`,
            }}
          />
          <View
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              transform: `rotate(-90deg)`,
              transformOrigin: `${paperSize[paper].width / 2}mm ${
                paperSize[paper].width / 2
              }mm`,
            }}
          >
            <View
              id="cornermark"
              style={{
                width: `${margin}mm`,
                height: `${margin}mm`,
                position: "absolute",
              }}
            >
              <Svg style={{ position: "absolute" }} viewBox={"0,0,1,1"}>
                <Polygon fill={CORNER_COLOR} points={"0.5,1, 1,1 1,0.5"} />
              </Svg>
            </View>
            <View
              id="cornerfold"
              style={{
                width: `${margin * 2}mm`,
                height: `${margin * 2}mm`,
                position: "absolute",
              }}
            >
              <Svg style={{ position: "absolute" }} viewBox={"0,0,1,1"}>
                <Line
                  stroke={"silver"}
                  x1={0}
                  x2={1}
                  y1={1}
                  y2={0}
                  strokeWidth={0.01}
                  strokeDasharray={"0.1, 0.05"}
                />
              </Svg>
            </View>

            <View
              id="label"
              style={{
                height: `${margin}mm`,
                width: `${paperWidth}mm`,
                display: "flex",
                position: "absolute",
                transform: "rotate(90deg)",
                transformOriginX: `${margin / 2}mm`,
                transformOriginY: `${margin / 2}mm`,
                alignItems: "center",
              }}
            >
              <Text
                style={{ fontSize: `4mm`, color: "grey", marginTop: "2mm" }}
              >
                {qr}
              </Text>
            </View>
            <View
              id="qrcode"
              style={{
                padding: `${margin}mm`,
                width: `${paperWidth}mm`,
              }}
            >
              {QRCodeModules(ForceVersion2(qr), paperWidth - margin * 2)}
            </View>
          </View>
          <View
            id="content-box"
            style={{
              position: "absolute",
              width: `${paperHeight - paperWidth}mm`,
              left: paperWidth + "mm",
              height: paperWidth + "mm",
              padding: margin + "mm",
            }}
          >
            <DescriptiveContent />
          </View>
        </Page>
      ))}
    </Document>
  );
}

function DescriptiveContent() {
  return (
    <>
      <Image
        src={"/marker.png"}
        style={{
          width: `${24.4}mm`,
          height: `${8.0}mm`,
          marginBottom: "5mm",
        }}
      />
      <Text
        style={{ fontWeight: "bold", fontSize: `4mm`, marginBottom: `2mm` }}
      >
        Instructions
      </Text>
      <Text style={{ fontSize: `3mm`, marginBottom: `2mm` }}>
        Position the QR code so that the corner indicated by the purple triangle
        matches the coordinate specified in Rhino. The corner of the sheet can
        be cut or folded to push the code against walls or other edges.
      </Text>
      <Text style={{ fontSize: `3mm`, marginBottom: `2mm` }}>
        If positioning your model with only a single marker, the model
        orientation will match the orientation of this sheet with the x-axis
        along the long edge and the y-axis along the short edge.
      </Text>
    </>
  );
}

/**
 * Forces QR code content to have a minimum length, prompting a Version 2 QR code
 * and thus a larger QR code with an alignment pattern.
 * @param content The input string
 * @returns The padded string
 */
function ForceVersion2(content: string) {
  while (content.length < 18) {
    content += "\n";
  }
  return content;
}

function QRCodeModules(content: string, width: number) {
  const matrix = QRJS(content, {
    errorCorrectLevel: 1,
    typeNumber: -1,
  });

  const moduleSize = width / matrix.moduleCount;

  const blocks = [];
  for (let y = 0; y < matrix.moduleCount; y++) {
    for (let x = 0; x < matrix.moduleCount; x++) {
      if (matrix.modules[y][x]) {
        blocks.push(
          <Rect
            key={x + "," + y}
            x={x * moduleSize}
            y={y * moduleSize}
            width={moduleSize}
            height={moduleSize}
            fill="#000000"
          />
        );
      }
    }
  }

  return (
    <>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Svg
          width={`${width}mm`}
          height={`${width}mm`}
          viewBox={`0 0 ${width} ${width}`}
          preserveAspectRatio={"xMidYMid"}
          style={{ width: `${width}mm`, height: `${width}mm` }}
        >
          {blocks}
        </Svg>
      </View>
    </>
  );
}
